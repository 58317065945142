import React from 'react';
import TableCell from './cells/TableCell';

/* eslint-disable react/prop-types */
const TableRow = props => {
  return (
    <tr>
      {props.children}
      <TableCell cells={props.row && props.row.cells} />
    </tr>
  );
};

export default TableRow;
