import React from 'react';
import TableHeaderVariationCell from './TableHeaderVariationCell';

/* eslint-disable react/prop-types */
const TableHeaderCell = props => {
  const handleVariationChange = event => {
    props.handleVariationChange(event);
  };
  if (props.cell && props.cell.variations) {
    return (
      <TableHeaderVariationCell
        handleVariationChange={handleVariationChange}
        cell={props}
      />
    );
  } else {
    return (
      <th scope={props.scope} rowSpan={props.rowSpan} colSpan={props.colSpan}>
        {props.cell && props.cell.text}
      </th>
    );
  }
};
export default TableHeaderCell;
