export const language = (language, word) => {
  if (language === 'no-NB') {
    return bokmal[word];
  } else {
    return nynorsk[word];
  }
};

const bokmal = { source: 'Kilde' };

const nynorsk = { source: 'Kjelde' };
