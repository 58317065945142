import { combineReducers } from 'redux';
import figure from './figure';
import table from './table';
import navigation from './navigation';
import errors from './errors';

export default combineReducers({
  figure,
  table,
  navigation,
  errors
});
