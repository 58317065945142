import React from 'react';
import './Relations.scss';
import ReactGA from 'react-ga';

/* eslint-disable react/prop-types */
const Relations = props => {
  let relationships;
  const origin = window.location.origin;
  const path = window.location.pathname.split('/');
  const correctPath = `/${path[1]}/${path[2]}`;
  const getType = type => (type === 'figure' ? 'Figur' : 'Tabell');
  if (props.relations) {
    relationships = props.relations.map((relation, index) => {
      const type = getType(relation.attachmentType);
      return (
        <li key={index}>
          <a
            href={`${origin}${correctPath}/${type}/${relation.label}`}
            onClick={() => {
              ReactGA.event({
                category: path[1] + ' ' + path[2],
                action: 'Open related',
                label: type + ' ' + relation.label
              });
            }}
          >
            Relatert til {type} {relation.label}
          </a>
        </li>
      );
    });
  }
  return (
    <React.Fragment>{relationships && <ul>{relationships}</ul>}</React.Fragment>
  );
};
export default Relations;
