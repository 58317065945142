import React from 'react';
import logo from '../../assets/hkdirWhiteLogo.svg';
import './BottomFooter.scss';

export const BottomFooter = () => {
  return (
    <footer className="app-footer">
      <div className="flex-container flex-column">
        <img src={logo} className="logo mr-4" alt="HK-dir logo" />
        <div className="text-field">
          <p>
            Direktoratet for høgare utdanning og kompetanse
            <br />
            Postboks 1093, 5809 Bergen
            <br />
            Tlf:
            <a href="tel:55303800" title="">
              55 30 38 00
            </a>
            <br />
            E-post:
            <a href="mailto:post@hkdir.no" title="">
              post@hkdir.no
            </a>
          </p>
          <p className="privacy">
            <a href="https://hkdir.no/personvernerklaering">
              Personvernerklæring
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default BottomFooter;
