import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

/* eslint-disable react/prop-types */
const TableHeaderVariationCell = props => {
  const [currentVariation, setVariation] = useState(props.cell.cell.text);
  return (
    <th
      style={{ width: `${props.cell.colSpan > 1 ? 'auto' : '20%'}` }}
      scope={props.cell.scope}
      rowSpan={props.cell.rowSpan}
      colSpan={props.cell.colSpan}
    >
      <Form.Group controlId="selectvariations">
        <Form.Control
          as="select"
          value={currentVariation}
          onChange={e => {
            let parsedJson = JSON.parse(e.target.value);
            setVariation(parsedJson['name']);
            props.handleVariationChange(parsedJson['url']);
          }}
        >
          <option>{currentVariation}</option>
          {props.cell.cell.variations.map((item, index) => {
            return (
              <option
                key={index}
                value={JSON.stringify({ name: item.name, url: item.url })}
              >
                {item.name}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </th>
  );
};

export default TableHeaderVariationCell;
