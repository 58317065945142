import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <h1>Whooops! </h1>
      <h2>The page you are looking for does not exist... </h2>
      <p>
        <br />
        <Link to="/">Go to Home </Link>
      </p>
    </React.Fragment>
  );
};

export default NotFoundPage;
