import * as figureAction from '../actions/figure';

const initialState = {
  figure: {}
};

const figureReducer = (state = initialState, action) => {
  switch (action.type) {
    case figureAction.FETCH_FIGURE:
      return {
        ...state,
        figure: action.figure
      };
    default:
      return state;
  }
};

export default figureReducer;
