import React from 'react';
import './SummaryRow.scss';

/* eslint-disable react/prop-types */
const SummaryRow = props => {
  let summary;
  let name;
  if (props.name) {
    name = (
      <th scope="row" rowSpan={props.data.cells.length + 1}>
        {props.name}
      </th>
    );
  }
  if (props.data && props.data.cells) {
    summary = props.data.cells.map((cell, index) => {
      return index === 0 ? (
        <td key={index} className="text-left">
          {cell.text}
        </td>
      ) : (
        <td key={index}>{cell.text}</td>
      );
    });
  }

  if (summary == null) {
    return null;
  }

  return (
    <tr className="SummaryRow">
      {name}
      {summary}
    </tr>
  );
};

export default SummaryRow;
