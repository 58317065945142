import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';

const CustomAlert = props => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const { errors } = props;
  useEffect(() => {
    if (errors.length > 0) {
      setError(errors);
      setShow(true);
    } else {
      setError('');
      setShow(false);
    }
  }, [errors]);

  if (show) {
    return (
      <Alert
        className="ml-2 mr-2"
        variant="danger"
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>En feil oppstod</Alert.Heading>
        <p> {error} </p>
      </Alert>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    errors: state.errors.api_error
  };
};

export default connect(mapStateToProps)(CustomAlert);
