import React from 'react';
import { connect } from 'react-redux';

const ReportMetadata = props => {
  let reportDesc;
  if (props.contents && props.contents.description) {
    reportDesc = props.contents.description;
  }
  return (
    <React.Fragment>
      {reportDesc && reportDesc.length > 0
        ? reportDesc.map((item, index) => (
            <p
              key={index}
              dangerouslySetInnerHTML={{
                __html: item
              }}
            ></p>
          ))
        : null}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    contents: state.navigation.collection
  };
};

export default connect(mapStateToProps)(ReportMetadata);
