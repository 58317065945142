import axios from 'axios';

let api = '/graphql';

if (process.env.NODE_ENV === 'development') {
  api = 'https://vedlegg.hkdir.no/graphql';
}

const graphApi = axios.create({
  baseURL: api
});

export default graphApi;
