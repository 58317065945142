import graph_axios from '../../api/axios_graphql';

export const FETCH_COLLECTION_ATTACHMENTS = 'FETCH_COLLECTION_ATTACHMENTS';
export const saveAllAttachments = response => {
  return {
    type: FETCH_COLLECTION_ATTACHMENTS,
    attachments: response.data.attachments
  };
};
export const fetchAllAttachments = id => {
  const query = `attachments(collectionId:"${id}"){
        label
        name
        id
        section
        attachmentType
        year
      }`;

  return dispatch => {
    graph_axios
      .get(`/?query={${query}}`)
      .then(response => {
        dispatch(saveAllAttachments(response.data));
      })
      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const saveAllCollections = response => {
  return {
    type: FETCH_COLLECTIONS,
    collections: response.data ? response.data.collections : []
  };
};

export const fetchCollections = year => {
  let selectedYear = Number(year);

  let query = 'collections {name, year, id, shortName}';
  if (year) {
    query = `collections(year:${selectedYear}) {name, year, id, shortName}`;
  }

  return dispatch => {
    graph_axios
      .get(`/?query={${query}}`)
      .then(response => {
        dispatch(saveAllCollections(response.data));
      })
      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const SEARCH_COLLECTIONS = 'SEARCH_COLLECTIONS';
export const saveAllContent = response => {
  return {
    type: SEARCH_COLLECTIONS,
    contents: response.data.contents
  };
};

export const searchCollections = (params, filterString) => {
  let query = '';
  if (params.shortName)
    query = `contents(year:${params.year}, shortName:"${params.shortName}", filter:"${filterString}") {id, collection, label, section, name, attachmentType, year}`;

  return dispatch => {
    graph_axios
      .get(`/?query={${query}}`)
      .then(response => {
        dispatch(saveAllContent(response.data));
      })

      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const FETCH_COLLECTION_CONTENT = 'FETCH_COLLECTION_CONTENT';
export const saveAllCollectionContent = response => {
  return {
    type: FETCH_COLLECTION_CONTENT,
    collection: response.data.collection
  };
};

export const fetchCollectionContent = params => {
  const query = `collection(year: ${params.year}, shortName: "${params.shortName}") {name, year, shortName, type, id, description,
  link {href, text}
  }`;
  return dispatch => {
    graph_axios
      .get(`/?query={${query}}`)
      .then(response => {
        dispatch(saveAllCollectionContent(response.data));
      })
      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const API_ERROR = 'API_ERROR';
export const catchError = error => ({
  type: API_ERROR,
  api_error: error
});

export const CURRENT_TITLE = 'CURRENT_TITLE';
export const addTitle = title => ({
  type: CURRENT_TITLE,
  current_title: title
});
