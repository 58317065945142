import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { fetchFigure } from '../../store/actions/figure';
import { connect } from 'react-redux';
import Chart from '../figures/charts/Chart';
import OpenFile from '../../components/common/OpenFile';
import Relations from '../common/Relations';
import Description from '../common/Description';
import { language } from '../../common/language';
import useWindowSize from '../../common/useWindowSize';

/* eslint-disable react/prop-types */
export const StaticFigure = props => {
  const {
    fetchFigure,
    match: { params }
  } = props;

  useEffect(() => {
    fetchFigure(params);
  }, [params, fetchFigure]);

  const windowSize = useWindowSize();
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    if (window.parent === window) return;

    let message = {
      messageType: 'heightChanged',
      value: containerRef.current.clientHeight,
      origin: window.location.toString()
    };

    // When opened in iframe, post the height so that the iframe can adjust it's height accordingly.
    window.parent.postMessage(message, '*');
  }, [windowSize, props.figure]);

  const urlParams = new URLSearchParams(props.location.search);
  const scale = urlParams.get('zoom');
  const fromArticle =
    urlParams.has('visning') && urlParams.get('visning') === 'artikkel';

  const iframe = urlParams.get('iframe') === 'true';

  const openInNewTab = (
    <a href={props.location.pathname} target="_blank" rel="noopener noreferrer">
      <OpenFile /> Åpne figur i ny fane
    </a>
  );

  const stuffAboveFigure = (
    <div className="p-3">
      {!fromArticle && (
        <h5 className="block pb-3 text-center">
          {props.figure.label} {props.figure.name}
        </h5>
      )}
      {openInNewTab}
    </div>
  );

  const stuffBelowFigure = (
    <>
      {!fromArticle && (
        <div
          className="pl-3 pr-3 static-figure-description"
          style={{ zoom: scale + '%' }}
        >
          <Description description={props.figure.description} />
          <Relations relations={props.figure.relationships} />
          <span>
            {language(props.figure.language, 'source')}: {props.figure.source}
          </span>
        </div>
      )}
    </>
  );

  return (
    <div ref={containerRef} className="static-container">
      {!iframe && stuffAboveFigure}
      {iframe && <div className="text-right pr-1">{openInNewTab}</div>}
      {props.figure.figures &&
        props.figure.figures.map((figure, index) => {
          return (
            <div key={index} style={{ zoom: scale + '%' }}>
              <Chart figure={figure} iframe={iframe} />
            </div>
          );
        })}
      {!iframe && stuffBelowFigure}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    figure: state.figure.figure
  };
};

export default connect(mapStateToProps, { fetchFigure })(StaticFigure);
