import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Layout from './components/layout/Layout';
import Root from './components/pages/Root';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import StaticTable from './components/pages/StaticTable';
import StaticFigure from './components/pages/StaticFigure';
import GA from './common/GoogleAnalytics';

function App() {
  return (
    <BrowserRouter>
      {GA.init() && <GA.RouteTracker />}
      <Switch>
        <Route
          path="/:shortName/:year/tabell/:label"
          exact
          component={StaticTable}
        />
        <Route
          path="/:shortName/:year/tabell/:label/variant/:variation"
          exact
          component={StaticTable}
        />
        <Route
          path="/:shortName/:year/figur/:label"
          exact
          component={StaticFigure}
        />
        <Route path="/samlinger" exact component={Layout} />
        <Route path="/:shortName/:year" exact component={Layout} />
        <Route path="/" component={Root} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
