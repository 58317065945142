import React from 'react';
import SummaryRow from '../SummaryRow';
import TableRow from './rows/TableRow';

/* eslint-disable react/prop-types */
const TableSection = props => {
  let rows;
  if (props.section) {
    rows = props.section.rows.map((row, index) => {
      return props.section.name && index === 0 ? (
        <TableRow key={index} row={row}>
          <th
            scope="row"
            rowSpan={
              props.section.rows.length + (props.section.summary ? 1 : 0)
            }
          >
            {props.section.name}
          </th>
        </TableRow>
      ) : (
        <TableRow key={index} row={row} />
      );
    });
  }

  return (
    <React.Fragment>
      {rows}
      <SummaryRow data={props.section && props.section.summary} />
    </React.Fragment>
  );
};

export default TableSection;
