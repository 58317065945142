import axios from '../../api/axios_rest';

export const FETCH_TABLE = 'FETCH_TABLE';
export const saveTable = response => {
  return {
    type: FETCH_TABLE,
    table: response.data
  };
};
export const fetchTable = params => {
  let url = generateUrl(params);
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(saveTable(response));
      })
      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const DOWNLOAD_TABLE = 'DOWNLOAD_TABLE';
export const downloadFile = response => {
  return {
    type: DOWNLOAD_TABLE,
    file: response
  };
};

export const downloadTable = params => {
  let url = generateUrl(params);
  const type =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  return dispatch => {
    axios
      .get(url, {
        headers: {
          Accept: type
        },
        responseType: 'blob'
      })
      .then(response => {
        dispatch(downloadFile(new Blob([response.data], { type: type })));
      })
      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const API_ERROR = 'API_ERROR';
export const catchError = error => ({
  type: API_ERROR,
  api_error: error.toString()
});

export let generateUrl = params => {
  if (params.variation) {
    return `/collections/${params.year}/${params.shortName}/tables/${params.label}/variations/${params.variation}`;
  } else {
    return `/collections/${params.year}/${params.shortName}/tables/${params.label}`;
  }
};
