import React from 'react';
import './Description.scss';

/* eslint-disable react/prop-types */
const Description = props => (
  <p className="DataDescription">
    {props.description && props.description.length === 1
      ? props.description[0]
      : props.description
      ? props.description.map((item, index) => <li key={index}>{item}</li>)
      : null}
  </p>
);

export default Description;
