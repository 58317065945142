import React from 'react';
import logo from '../../assets/hkdirLogo.svg';
import './TopHeader.scss';
import { connect } from 'react-redux';
import ReportMetadata from '../common/ReportMetadata';

export const TopHeader = props => {
  return (
    <header className="flex-container flex-column mb-3">
      <img src={logo} className="diku-logo" alt="HK-dir logo" />
      {props.content && props.content.link && props.content.link.href && (
        <div className="blue mt-4 mb-4 minus-margin">
          &lang; &nbsp;
          <a
            href={
              props.content && props.content.link && props.content.link.href
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="medium-text blue">
              {props.content && props.content.link && props.content.link.text}
            </span>
          </a>
        </div>
      )}
      <span className="bold medium-text">
        {props.content && props.content.type}
      </span>
      <h1 className="title blue mb-5">{props.currentTitle}</h1>
      <div className="metadata">
        <ReportMetadata />
      </div>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    currentTitle: state.navigation.current_title,
    content: state.navigation.collection
  };
};

export default connect(mapStateToProps)(TopHeader);
