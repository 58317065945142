import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import './Layout.scss';
import Spinner from '../common/Spinner';
import NotFoundPage from '../pages/NotFoundPage';
import TopHeader from './TopHeader';
import BottomFooter from './BottomFooter';
import CustomAlert from '../common/CustomAlert';
const Home = lazy(() => import('../pages/Home'));
const TableOfContents = lazy(() => import('../navigation/TableOfContents'));

export const Layout = () => {
  return (
    <React.Fragment>
      <TopHeader />
      <div className="App">
        <CustomAlert />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/:shortName/:year" exact component={TableOfContents} />
            <Route path="/samlinger" exact component={Home} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Suspense>
        <BottomFooter />
      </div>
    </React.Fragment>
  );
};

export default Layout;
