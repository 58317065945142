import axios from 'axios';

let api = '/api';

if (process.env.NODE_ENV === 'development') {
  api = 'https://vedlegg.hkdir.no/api';
}

const dikuApi = axios.create({
  baseURL: api
});

export default dikuApi;
