import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';

// Initialize exporting module.
Exporting(Highcharts);
Highcharts.setOptions({
  colors: [
    '#e72f72', //$new_primary_color_magenta
    '#0025a0', //$new_primary_color_blue
    '#ea591d', //$new_primary_color_dark_orange
    '#630879', //$new_secondary_color_dark_purple
    '#ff8c43', //$new_primary_color_light_orange
    '#a80037', //$new_secondary_color_dark_red
    '#ffc948', //$new_primary_color_yellow
    '#9b3699', //$new_primary_color_purple
    '#000000', //$color_black
    '#a2a4a5', //$new_primary_color_grey
    '#171b4e' // $new_secondary_color_dark_blue
  ],
  lang: {
    decimalPoint: ',',
    thousandsSep: ' ',
    numericSymbolMagnitude: 1000000000
  },
  xAxis: {
    labels: {
      autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
    }
  },
  yAxis: {
    reversedStacks: false
  }
});

/* eslint-disable react/prop-types */
const Chart = props => {
  if (props.iframe === true)
    Highcharts.setOptions({
      navigation: { buttonOptions: { enabled: false } }
    });
  return (
    <HighchartsReact highcharts={Highcharts} options={props.figure} immutable />
  );
};

export default Chart;
