import React from 'react';
import TableHeaderCell from './rows/cells/TableHeaderCell';
import TableHeaderRow from './rows/TableHeaderRow';

/* eslint-disable react/prop-types */
const TableHeader = props => {
  let firstRow; //First row of header
  let secondRow; //Second row of header
  let thirdRow; //Third row of header
  let colSpan = []; //Number of columns to span
  let rowSpan = []; // Number of rows to span

  const handleVariationChange = event => {
    props.handleVariationChange(event);
  };
  if (props.headerItems) {
    thirdRow = props.headerItems.cells.map((first, firstindex) => {
      rowSpan[0] = 1;
      if (first.subCells && first.subCells.length > 0) {
        rowSpan[1] = 2;
        colSpan[firstindex] = first.subCells.length;
        return first.subCells.map(second => {
          if (second.subCells && second.subCells.length > 0) {
            rowSpan[2] = 3;
            colSpan[firstindex] =
              second.subCells.length * first.subCells.length;
            return second.subCells.map((cell, index) => {
              return <TableHeaderCell key={index} cell={cell} scope="col" />;
            });
          }
          return null;
        });
      }
      return null;
    });

    secondRow = props.headerItems.cells.map(data => {
      if (data.subCells && data.subCells.length > 0) {
        return data.subCells.map((cell, index) => {
          return cell.subCells ? (
            <TableHeaderCell
              key={index}
              cell={cell}
              colSpan={cell.subCells.length}
              scope="colgroup"
            />
          ) : (
            <TableHeaderCell key={index} cell={cell} scope="col" />
          );
        });
      }
      return null;
    });

    firstRow = props.headerItems.cells.map((cell, index) => {
      return cell.subCells ? (
        <TableHeaderCell
          key={index}
          cell={cell}
          colSpan={colSpan[index]}
          scope="colgroup"
          handleVariationChange={handleVariationChange}
        />
      ) : (
        <TableHeaderCell
          handleVariationChange={handleVariationChange}
          key={index}
          cell={cell}
          rowSpan={rowSpan.length}
        />
      );
    });
  }
  return (
    <thead>
      <TableHeaderRow>{firstRow}</TableHeaderRow>
      <TableHeaderRow>{secondRow}</TableHeaderRow>
      <TableHeaderRow>{thirdRow}</TableHeaderRow>
    </thead>
  );
};

export default TableHeader;
