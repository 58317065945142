import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { downloadTable } from '../../store/actions/table';
import download from '../../assets/download.svg';
import ReactGA from 'react-ga';

/* eslint-disable react/prop-types */
export const DownloadFile = props => {
  const { downloadTable, file } = props;

  useEffect(() => {
    if (file) {
      const uri = window.URL.createObjectURL(file);
      if (navigator.msSaveBlob) {
        return navigator.msSaveBlob(file, `${props.data.label}.xlsx`);
      } else {
        const link = document.createElement('a');
        link.download = `${props.data.label}.xlsx`;
        link.href = uri;

        document.body.appendChild(link);
        link.click();

        // Cleanup the DOM
        document.body.removeChild(link);
      }
    }
  }, [file, props.data.label]);

  const fetchFile = () => {
    ReactGA.event({
      category: props.data.shortName + ' ' + props.data.year,
      action: 'Downloading excel',
      label: props.data.label
    });
    downloadTable(props.data);
  };

  return (
    <button onClick={() => fetchFile()} className="download-button">
      <img src={download} alt="Last ned" /> Last ned tabell (xlsx)
    </button>
  );
};

const mapStateToProps = state => {
  return {
    file: state.table.file
  };
};

export default connect(mapStateToProps, { downloadTable })(DownloadFile);
