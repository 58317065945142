import axios from '../../api/axios_rest';

export const FETCH_FIGURE = 'FETCH_FIGURE';
export const saveFigure = response => {
  return {
    type: FETCH_FIGURE,
    figure: response.data
  };
};
export const fetchFigure = params => {
  return dispatch => {
    axios
      .get(
        `/collections/${params.year}/${params.shortName}/figures/${params.label}`
      )
      .then(response => {
        dispatch(saveFigure(response));
      })
      .catch(e => {
        dispatch(catchError(new Error(e)));
      });
  };
};

export const API_ERROR = 'API_ERROR';
export const catchError = error => ({
  type: API_ERROR,
  api_error: error
});
