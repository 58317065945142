const initialState = {
  api_error: {}
};

const errors = (state = initialState, action) => {
  switch (action.type) {
    case 'API_ERROR':
      return {
        ...state,
        api_error: action.api_error
      };
    default:
      return { state, api_error: {} };
  }
};

export default errors;
