import * as navigationActions from '../actions/navigation';

const initialState = {
  attachments: [],
  contents: [],
  collections: [],
  collection: {},
  current_title: ''
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case navigationActions.CURRENT_TITLE:
      return {
        ...state,
        current_title: action.current_title
      };
    case navigationActions.FETCH_COLLECTION_ATTACHMENTS:
      return {
        ...state,
        attachments: action.attachments
      };
    case navigationActions.FETCH_COLLECTIONS:
      return {
        ...state,
        collections: action.collections
      };
    case navigationActions.SEARCH_COLLECTIONS:
      return {
        ...state,
        contents: action.contents
      };
    case navigationActions.FETCH_COLLECTION_CONTENT:
      return {
        ...state,
        collection: action.collection
      };
    default:
      return state;
  }
};

export default navigationReducer;
