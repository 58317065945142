import React, { useEffect, useRef, useLayoutEffect } from 'react';
import '../Table/Table.scss';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import { fetchTable } from '../../store/actions/table';
import TableHeader from '../Table/header/TableHeader';
import TableSection from '../Table/body/sections/TableSection';
import SummaryRow from '../Table/body/SummaryRow';
import DownloadFile from '../../components/common/DownloadFile';
import OpenFile from '../../components/common/OpenFile';
import Relations from '../common/Relations';
import Description from '../common/Description';
import { language } from '../../common/language';
import useWindowSize from '../../common/useWindowSize';

/* eslint-disable react/prop-types */
export const StaticTable = props => {
  let headerItems;
  let tableSections;
  let mainSummaryRow;

  const {
    fetchTable,
    match: { params }
  } = props;

  useEffect(() => {
    fetchTable(params);
  }, [fetchTable, params]);

  const windowSize = useWindowSize();
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    if (window.parent === window) return;

    let message = {
      messageType: 'heightChanged',
      value: containerRef.current.clientHeight,
      origin: window.location.toString()
    };

    // When opened in iframe, post the height so that the iframe can adjust it's height accordingly.
    window.parent.postMessage(message, '*');
  }, [windowSize, props.tableData]);

  const handleVariationChange = selectedVariation => {
    params.variation = selectedVariation;
    fetchTable(params);
  };

  if (props.tableData.table) {
    headerItems = props.tableData.table.header;
    tableSections = props.tableData.table.sections.map((value, index) => {
      return <TableSection key={index} section={value} />;
    });
    mainSummaryRow = props.tableData.table.summary;
  }

  const urlParams = new URLSearchParams(props.location.search);
  const scale = urlParams.get('zoom');
  const fromArticle =
    urlParams.has('visning') && urlParams.get('visning') === 'artikkel';

  const iframe = urlParams.get('iframe') === 'true';

  const stuffAboveTable = (
    <div className="p-3">
      {!fromArticle && (
        <h5 className="block pb-3 text-center">
          {props.tableData.label} {props.tableData.name}
        </h5>
      )}
      <a
        className="mr-3"
        href={props.location.pathname}
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenFile /> Åpne tabell i ny fane
      </a>
      <DownloadFile data={params} />
    </div>
  );

  const stuffBelowTable = (
    <>
      {!fromArticle && (
        <div
          className="pl-3 pr-3 static-table-description"
          style={{ zoom: scale + '%' }}
        >
          <Description description={props.tableData.description} />
          <Relations relations={props.tableData.relationships} />
          <span>
            {language(props.tableData.language, 'source')}:{' '}
            {props.tableData.source}
          </span>
        </div>
      )}
    </>
  );

  const tableDiv = (
    <div ref={containerRef} className="static-container ">
      {!iframe && stuffAboveTable}
      <Table
        striped
        bordered
        hover
        className="CustomTable"
        style={{ zoom: scale + '%' }}
      >
        <TableHeader
          handleVariationChange={handleVariationChange}
          headerItems={headerItems}
        />
        <tbody>
          {tableSections}
          {mainSummaryRow &&
            mainSummaryRow.rows.map((row, index) => {
              return (
                <SummaryRow
                  key={index}
                  data={row}
                  name={
                    mainSummaryRow.name && index === 0
                      ? mainSummaryRow.name
                      : ''
                  }
                />
              );
            })}
        </tbody>
      </Table>
      {!iframe && stuffBelowTable}
    </div>
  );

  return (
    <>
      <div className="w-75 mx-auto d-none d-xl-flex">{tableDiv}</div>
      <div className="d-xl-none">{tableDiv}</div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    tableData: state.table.table
  };
};

export default connect(mapStateToProps, { fetchTable })(StaticTable);
