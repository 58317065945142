import React from 'react';

const TableCell = props => {
  let cell;
  if (props.cells) {
    cell = props.cells.map((cell, index) => {
      return index === 0 ? (
        <td key={index} className="text-left">
          {cell.text}
        </td>
      ) : (
        <td key={index}>{cell.text}</td>
      );
    });
  }

  return cell;
};

export default TableCell;
