import * as tableAction from '../actions/table';

const initialState = {
  table: {},
  file: {}
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case tableAction.FETCH_TABLE:
      return {
        ...state,
        table: action.table
      };
    case tableAction.DOWNLOAD_TABLE:
      return {
        ...state,
        file: action.file
      };
    default:
      return { state, table: {} };
  }
};

export default tableReducer;
